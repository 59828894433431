.header {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: var(--siteBackgroundAlpha);
  padding: 30px 20px;
  width: 100%;
  z-index: 2;
  backdrop-filter: saturate(180%) blur(20px);
  height: 98px;
}

.logout {
  margin: 0;
}

.overflow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.list {
  padding-bottom: 30px;
  flex: 1;
}

.error {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  opacity: 0.5;
}

.error h2 {
  font-weight: 400;
}

.error p {
  font-size: 14px;
  margin-top: 10px;
}
