.field {
  width: 50%;
}

.full {
  width: 100%;
}

.field:not(:last-child) {
  border-bottom: 1px solid var(--borderColor);
}

.label {
  display: block;
  opacity: 0.5;
  font-size: 12px;
  margin-top: 5px;
}

.field input:not([type="checkbox"]) {
  width: 100%;
  height: 32px;
  border: none;
  font-size: 16px;
  background: transparent;
}
