.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.horizontal {
  flex-direction: row;
}

.horizontal .spinner {
  margin-bottom: 0;
  margin-right: 10px;
}

.spinner {
  display: block;
  margin-bottom: 15px;
  fill: none;
  stroke: var(--textColor);
  stroke-linecap: round;
}
