.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 5px 30px;
  font-size: 14px;
  color: var(--buttonText);
  background-color: var(--buttonBackground);
  border-radius: 3px;
  border: none;
  flex-shrink: 0;
}

.button:active {
  opacity: 0.8;
}
