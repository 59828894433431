:root {
  --siteBackground: #efeff4;
  --siteBackgroundAlpha: rgba(239, 239, 244, 0.8);
  --textColor: black;
  --fontFamily: -apple-system, BlinkMacSystemFont, sans-serif;
  --buttonBackground: #5ca0f8;
  --buttonText: #fff;

  --topCornerRadius: 5px;

  --formBackground: #fff;
  --borderColor: #e6e6e6;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: var(--fontFamily);
  text-decoration: none;
  color: inherit;
  outline: none;
}

html {
  background: #000;
  font-size: 16px;
  color: var(--textColor);
  /* user-select: none; */
}

/* input {
  user-select: text;
} */

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

strong {
  font-family: var(--fontFamily);
  font-weight: 600;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--siteBackground);
}

.screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.submit {
  width: auto;
  margin: 20px;
  height: 45px;
}

.borderless-button {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  margin-bottom: 20px;
  padding: 5px 30px;
}

.vertical-center,
.centered {
  justify-content: center;
}

.horizontal-center,
.centered {
  align-items: center;
}

/**
 * Standalone view (running when added to springboard)
 */

#root.standalone,
#root.standalone .nav {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --siteBackground: #000;
    --siteBackgroundAlpha: rgba(0, 0, 0, 0.8);
    --textColor: #fbfafb;
    --buttonBackground: #5ca0f8;
    --buttonText: #fff;

    --formBackground: #1d1b1e;
    --borderColor: #2a282b;
  }
}
