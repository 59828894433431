.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  appearance: none;
  background: transparent;
  border: none;
  padding: 20px 50px;
}

.button:active {
  opacity: 0.5;
}

.input {
  appearance: none;
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.icon {
  fill: var(--textColor);
}

.message {
  margin-top: 25px;
}

.error {
  padding-bottom: 10px;
  font-size: 13px;
  color: red;
}
