.overlay {
  text-align: center;
  cursor: pointer;
  pointer-events: none;
}

.overlay:before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;
  backdrop-filter: blur(15px);
}

.overlayOpen:before {
  opacity: 1;
  pointer-events: all;
}

.overlayOpen .preview {
  position: relative;
  z-index: 20;
  transform: scale(2);
}

.overlay .preview {
  pointer-events: all;
  transform-origin: 50% -100%;
  transition: transform 200ms;
}

.preview {
  max-height: 150px;
  align-self: center;
  margin: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
