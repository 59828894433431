.switch {
  display: flex;
  height: 53px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 5px;
}

.switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 46px;
  height: 26px;
  background-color: var(--borderColor);
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}

.switch i:after {
  content: "";
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.switch:active i:after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}

.switch:active input:checked + i:after {
  transform: translate3d(16px, 2px, 0);
}

.switch input:checked + i {
  background-color: #4bd763;
}

.switch input {
  display: none;
}

.switch input:checked + i:after {
  transform: translate3d(22px, 2px, 0);
}
