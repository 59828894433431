.booking {
  width: 100%;
  display: flex;
  padding: 5px 0;
  cursor: pointer;
}

.poster {
  height: 75px;
  max-width: 60px;
  object-fit: contain;
}

.bookingInfo {
  position: relative;
  flex: 1;
  padding-left: 10px;
  padding-right: 40px;
}

.title {
  font-size: 18px;
  margin-bottom: 5px;
}

.venue {
  font-size: 13px;
  opacity: 0.5;
}

.date {
  font-size: 13px;
  opacity: 0.5;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 5px;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  fill: var(--textColor);
}
